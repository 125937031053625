@font-face {
  font-family: "Hearthstone";
  src: local("Hearthstone"), url(./fonts/HearthStone.ttf) format("truetype");
}
@font-face {
  font-family: "OpenSans";
  src: local("OpenSans"), url(./fonts/OpenSans.ttf) format("truetype");
}
body {
  overflow-x: hidden !important;
}
html {
  overflow-x: hidden !important;
}

section {
  float: left;
  width: 100%;
  height: auto;
  padding: 50px 0;
  margin: 0;
}
* {
  box-sizing: border-box;
}
[class*="col-"] {
  float: left;
  padding: 5px;
  border: none;
}
.equal-col {
  display: flex;
  align-items: center;
  justify-content: center;
}

/*  homepage   -------------------------------  */
.header {
  margin-bottom: 30px;
}

.birth-center {
  text-align: center;
}
.header {
  text-align: center;
}
.title-birthstones p {
  font-size: 30px !important;
}

.logo {
  max-width: 230px;
}

p {
  font-family: "OpenSans" !important;
}
h2 {
  font-family: "Hearthstone";
  font-weight: 300;
  font-size: 65px;
}

.section1 {
  float: left;
  width: 100%;
  height: auto;
  padding: 0 5vw;
  margin: 0;
  margin-top: 5vw !important;
}
.section1 h2 {
  text-align: center;
}
.section1 p {
  font-size: 16px;
}
.section1 img {
  width: 100%;
  height: auto;
}
.col-1 {
  width: 100%;
}
.col-2 {
  width: 50%;
}
.col-3 {
  width: 30%;
}
.col-4 {
  width: 22%;
}
.col-5 {
  width: 48%;
}
.col-6 {
  width: 60%;
}
.col-7 {
  width: 40%;
}

@media only screen and (max-width: 1190px) {
  .col1 {
    width: 100%;
    float: left;
    display: inline;
    flex-direction: column;
  }
  .content,
  .col-5,
  .col-3,
  .col-4,
  [class*="col--"] {
    width: 100%;
    padding: 0px;
  }
  section1 {
    padding: 0;
  }
  p {
    font-size: 15px;
  }
  h2 {
    font-size: 35px;
  }
  .landing-col {
    width: 100% !important;
  }
}

.videoAntonia {
  max-width: 100%;
  min-width: 100%;
}
.videoSoulmate {
  max-width: 100%;
  min-width: 100%;
}
.videoExclusive {
  max-width: 100%;
  min-width: 100%;
}
.videoDropthemic {
  max-width: 100%;
  min-width: 100%;
}
/*  ----------------------------------------------------------------- descktop  love story     */

.love-story {
  columns: 3 200px;
  width: 100%;
  margin: 0 auto;
}
.love-story div {
  width: 150px;
  padding-bottom: 15px;
  display: inline-block;
  width: 100%;
}
.love-story img {
  width: 100%;
}
.love-story h2 {
  text-align: center;
  font-size: 3vw;
  font-weight: 400;
}
.love-story p {
  text-align: center;
  font-size: 0.9vw;
  font-weight: 400;
  line-height: 1.2vw;
  padding-bottom: 2vw;
}
.grid-item-1,
.grid-item-5 {
  padding-top: 4.8vw;
}
.space-15 {
  margin-bottom: 15px;
}
.mobile-setup {
  display: none !important;
}

@media only screen and (max-width: 1190px) {
  .center h1{
font-size:25px !important;
  }
  .header{
    margin-bottom:0px !important;
    }
  .separator1 {
    background: none !important;
    height: auto !important;
    display: block !important;
  }
  .sep1 {
    background: none !important;
    height: auto !important;
    display: block !important;
  }
  .separator1-item-1 {
    width: 100% !important;
    float: left !important;
  }
  .mobil {
    display: block !important;
    float: left !important;
    width: 100% !important;
  }
  .desc {
    display: none !important;
  }
  .footer-top p {
    font-size: 22px !important;
    padding: 30px 5px !important;
  }
  .col1 {
    width: 100%;
    float: left;
    display: inline;
    flex-direction: column;
  }
  .content,
  .col-5,
  .col-3,
  .col-4,
  [class*="col--"] {
    width: 100%;
    padding: 0px;
  }
  section1 {
    padding: 0;
  }
  section1 p {
    font-size: 15px;
  }
  section1 h2 {
    font-size: 25px;
  }

  /*   ----------------------------------------------------------  mobil love story    */
  .love-story {
    columns: 1 200px;
  }
  .grid-item-4 {
    margin-bottom: -20px;
  }
  .mobile-setup {
    display: inline !important;
    float: left;
    width: 100%;
  }
  .desktop-setup {
    display: none !important;
  }
  .love-story h2 {
    font-size: 30px;
    font-weight: 400;
  }
  .love-story p {
    font-size: 18px;
    font-weight: 400;
    line-height: 21px;
  }
  .title-birthstones p {
    font-size: 18px !important;
  }
}

.no-padding {
  padding: 0 !important;
}

/*   ----------------------------------------------------------  zoom effect    */
.zoom {
  cursor: pointer;
  transition: transform 1.5s;
}

.zoom:hover {
  -ms-transform: scale(1.15); /* IE 9 */
  -webkit-transform: scale(1.15); /* Safari 3-8 */
  transform: scale(1.15);
}

.zoomsmall {
  cursor: pointer;
  transition: transform 1s;
}

.zoomsmall:hover {
  -ms-transform: scale(1.1); /* IE 9 */
  -webkit-transform: scale(1.1); /* Safari 3-8 */
  transform: scale(1.1);
}

/*  ----------------------------------------------------------------- descktop  separator1     */

.separator1 {
  width: 100%;
  margin: 5vw 0;
  display: flex;
  align-items: center;
  justify-content: center;
}
.sep1 {
  background-position: center;
  background-repeat: no-repeat;
  height: 100vh;
  background-size: 100vw auto;
  display: flex;
  align-items: center;
  justify-content: center;
}
.separator1-item-1 {
  width: 50%;
  padding: 0;
  margin: 0 auto;
}
.separator1-item-2 {
  width: 16.66%;
  padding: 4px;
}
.mobil {
  display: none;
}

/*  ----------------------------------------------------------------- descktop  footer1     */

.news-center {
  text-align: center;
}
.footer1 {
  width: 100%;
  margin: 0;
}
.footer-top {
  width: 100%;
  margin: 0;
  text-align: center;
}
.footer-top img {
  width: 65px;
  height: auto;
}
.footer-top p {
  font-size: 30px;
  padding: 30px;
}
.footer-top .button1,
.footer-bottom .button1 {
  font-family: "OpenSans";
  font-size: 20px;
  width: 120px;
  padding: 8px 15px;
  border: 1px solid #eb863c;
  color: #777;
  font-weight: 600;
  margin: 20px auto;
  cursor: pointer;
}
.footer-top .button1:hover,
.footer-bottom .button1:hover {
  color: #000;
  transition: all 0.8s ease-in-out;
}
.footer-grid-item {
  width: 33.3%;
  padding: 3px;
  float: left;
}
.footer-bottom {
  float: left;
  width: 100%;
  padding: 45px 0px;
}
.footer-bottom .button1 {
  width: 145px;
  font-weight: 600;
  text-align: center;
}
.footer-bottom .button1 span {
  font-weight: 400;
}
.copy {
  font-family: "OpenSans";
  font-size: 20px;
  padding: 40px 30px;
  width: 100%;
  text-align: center;
  float: left;
  color: #777;
}

.center .slick-center img {
  outline: none;
  opacity: 1;
  transform: scale(1.3);
}

.center img {
  outline: none;
  opacity: 0.8;
  transition: all 1s ease;
  transform: scale(0.7);
}
.center .slick-center h1 {
  outline: none;
  opacity: 1;
  transform: scale(1);
  line-height: 0px;
}

.center h1 {
  outline: none;
  opacity: 0.8;
  transition: all 1s ease;
  transform: scale(0.5);
  line-height: 0px;
}

.center .slick-center p {
  transform: scale(1);
  opacity: 1;
}

.center p {
  transform: scale(0.1);
  opacity: 0;
  transition: all 0.5s ease;
}

.center h3 {
  outline: none;
  opacity: 0 !important;
  transition: all 1s ease;
  transform: scale(0.8);
  line-height: 0px;
}
.center .slick-center h3 {
  outline: none;
  opacity: 1 !important;
  transform: scale(1);
  line-height: 0px;
}

.center {
  outline: none;
}
.slick-slide {
  outline: none;
}
.slick-slide div {
  outline: none;
  text-align: center;
}

.center h1 {
  font-family: "OpenSans";
  color: #AEAEAE;

  font-size: 35px;
  font-weight: 200;
}
.center h3 {
  font-family: "Hearthstone";
  color: black !important;

  font-size: 45px;
  font-weight: 200;
}
.titlu-categorie{
  color:black;
  text-align: center;
  font-size: 20px !important;
  font-weight: bold;
  }

@media only screen and (min-width: 1200px) {
  .increase-width {
    width: 35%;
  }
 .title-page-top{
  font-size: 3vw !important;
 }

}
.mobile-reveal{display:none}
@media only screen and (max-width: 450px) {
  .button1{
    width:100% !important;
    }
.mobile-cta-birth{
  padding-top: 20px;
}
  .birth-mobile-spread{
    width: 150vw !important;
    margin-left: -25vw !important;
  }
  .titlu-categorie{
    margin-top: 5px;
    padding-left: 10%;
    padding-right: 10%;
    font-size: 15px!important;
    }
  .mobile-erase{
display:none !important;
  }
  .mobile-reveal{display:block !important}
  .birth-trilogy{
    margin-top: 15px;
  }
  .cta-colectie .button1{
    width: 82vw !important;
  }
.birth-center{
  margin-top: 81px;

}
 .variable-width .slick-list{padding-left: 15px !important;}
  .slick-slider{
    margin-bottom: -50px !important;
  }
  .center .slick-center p {
    min-width: 240px;
    margin-left: -35px;
  }
  .center h3 {
    margin-left: 0px!important;
  }

  .center .slick-center h1 {
    min-width: 200px;
    margin-left: -15%;
  }

  .section1 p{
    text-align: center;
  }
}
a {
  text-decoration: none !important;
}
.article-titles {
  color: black !important;
  padding-left: 10px;
}
.section1 p{
  text-align: center;
}

.cta-colectie .button1
{
  font-family: "OpenSans";
  font-size: 15px;
  width: 250px;
  padding: 8px 15px;
  border: 1px solid #eb863c;
  color: #777;
  font-weight: 600;
  margin: 20px auto;
  cursor: pointer;
  text-align: center;
}
.cta-colectie .button1:hover{
  color: #000;
  transition: all 0.8s ease-in-out;
}
.cta-colectie{
  text-align: center;
}
iframe {
  min-width: 250px !important;
  max-height: 200px !important;
}

.news-slider .variable-width .slick-slide img{
  margin: 0px !important;
}
.news-slider .slick-track{
  display: flex !important;
  padding-left: 13%; 
}
.news-slider .slick-slide div{
  padding-right: 10px;
}

/*Changes-10mai----------*/
.img-categorie{
  margin:0 !important;
}

  .margin-reducer{
margin-top:15px !important;
  }
.description-emerald-bold{
font-weight: bold;
}
.underline-category{
  border-bottom: 1px solid #eb863c;
  padding-bottom: 3px;
}
